
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { AxiosRequestConfig } from "axios";
import { useI18n } from "vue-i18n";
import { event } from "vue-gtag";

interface Course {
  trimester: string;
  subject: string;
  title: string;
  module: string;
  desc: string;
  resources: File[];
  status: string;
  students: string[];
}

interface File {
  name: string;
  url: string;
  storedName: string;
}

interface Module {
  _id: string;
  name: string;
  subjects: Subject[];
  status: string;
}

interface Subject {
  _id: string;
  name: string;
  status: string;
}

export default defineComponent({
  name: "edit-course",
  components: {
    ErrorMessage,
    Field,
    Form,
    VueEditor,
  },
  props: {
    widgetClasses: String,
    id: String,
  },
  setup() {
    const { t } = useI18n();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    // eslint-disable-next-line
    const upload = ref<any>(null);
    const subjects = ref<Subject[]>([]);
    const modules = ref<Module[]>([]);
    const uploadPercentage = ref(0);

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const courseID = route.params.cid;
    const classID = route.params.id;

    const jwtTrimester = JwtService.getTrimester();
    let trimester = "1";
    if (jwtTrimester && jwtTrimester != "0") trimester = jwtTrimester;

    const spec = ref<string>("0");

    const course = ref<Course>({
      trimester,
      subject: "",
      title: "",
      module: "",
      desc: "",
      resources: [],
      status: "",
      students: [],
    });

    const validationSchema = Yup.object().shape({
      title: Yup.string().required(t("course.requiredTitle")),
      subject: Yup.string().required(t("course.requiredSubject")),
    });

    const getFileName = (file: string): string => {
      const t = file.indexOf("--");
      const fileName = file.substring(t == -1 ? 0 : t + 2);
      return fileName;
    };

    const getFileUrl = (file: string): string => {
      return (
        store.getters.serverConfigUrl.base_url + "/" + file.replace(/\\/g, "//")
      );
    };

    ApiService.post("/teacher/classRommDetails", {
      query: {
        teacher: store.getters.currentUser._id,
        trimester: trimester,
      },
    })
      .then(({ data }) => {
        subjects.value = [];
        modules.value = [];
        const classroom = data.find(
          (el) => el.classRoom._id == route.params.id
        );
        if (classroom) {
          subjects.value = classroom.subjects;
        }

        //remove duplicated
        subjects.value = subjects.value.filter(
          (subject, index) =>
            subjects.value.findIndex((s) => s._id == subject._id) === index &&
            !modules.value.find((m) =>
              m.subjects.find((ss) => ss._id == subject._id)
            ) &&
            subject.status !== "inactive"
        );

        getStudents([classroom.classRoom._id]);
      })
      .catch((e) => console.log(e));

    ApiService.get(`/lms/course/${courseID}`)
      .then(({ data }) => {
        course.value = {
          subject: data.subject._id,
          title: data.title,
          module: data.module,
          desc: data.desc,
          resources: [],
          trimester: data.trimester ? data.trimester : "0",
          status: data.status,
          students: data.students,
        };
        data.resources.forEach((file) => {
          course.value.resources.push({
            storedName: file,
            name: getFileName(file),
            url: getFileUrl(file),
          });
        });

        if (!!data.students && data.students.length > 0) spec.value = "1";

        if (data.status == "validated") router.push("/404");
      })
      .catch((e) => {
        console.log(e);
      });

    // eslint-disable-next-line
    const uploadFiles = ref<any>([]);

    const updateUploadFiles = (file, files) => {
      uploadFiles.value = files;
    };

    const submit = () => {
      const data = new FormData();

      for (const name in course.value) {
        if (!["status", "resources"].includes(name))
          data.append(name, course.value[name]);
      }

      if (uploadFiles.value.length > 0) {
        uploadFiles.value.forEach((file) => {
          if (file.raw) data.append("resources", file.raw, file.raw.name);
          else data.append("resources[]", file.storedName);
        });
      } else {
        course.value.resources.map((file) => {
          data.append("resources[]", file.storedName);
        });
      }

      data.delete("students");
      if (course.value.students.length != 0) {
        course.value.students.forEach((st: string) => {
          data.append("students", st);
        });
      }

      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.patch(
        `/lms/course/${courseID}`,
        data as AxiosRequestConfig,
        {
          onUploadProgress: function (progressEvent) {
            uploadPercentage.value = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        } as any
      )
        .then(() => {
          event("Edit course", {
            event_category: "Course",
            event_label: "Classe profile",
            value: 1,
          });

          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }

          Swal.fire({
            text: t("course.editedCourseInfo"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("course.okay"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            router.push(`/classe/${classID}/coursDetails/${courseID}`);
          });
        })
        .catch((err) => {
          if (err.response.status == 413)
            Swal.fire({
              text: t("course.filesTooLarge"),
              icon: "warning",
              buttonsStyling: false,
              confirmButtonText: t("course.okay"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          else
            Swal.fire({
              text: t("course.errorText"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("course.tryAgain"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    const handleExceed = () => {
      Swal.fire({
        text: t("course.maxFilesUploadExceed"),
        icon: "info",
        buttonsStyling: false,
        confirmButtonText: t("course.tryAgain"),
        customClass: {
          confirmButton: "btn fw-bold btn-light-info",
        },
      });
    };

    const customColorMethod = (percentage: number) => {
      if (percentage < 30) {
        return "#909399";
      }
      if (percentage < 70) {
        return "#e6a23c";
      }
      return "#67c23a";
    };

    const studentList = ref<any[]>([]);

    const getStudents = (classrooms: string[]) => {
      const sy = window.localStorage.getItem("activeSchoolarYear");
      const match = {};
      match[`schoolarYearsHistory.${sy}`] = { $in: classrooms };
      ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $project: {
              _id: 1,
              firstName: 1,
              lastName: 1,
              classRoom: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          studentList.value = data;
        })
        .catch((error) => console.log(error));
    };

    const clearStudents = () => {
      course.value.students = [];
    };

    return {
      t,
      courseID,
      classID,
      subjects,
      modules,
      upload,
      course,
      validationSchema,
      submit,
      submitButtonRef,
      updateUploadFiles,
      handleExceed,
      uploadPercentage,
      customColorMethod,
      studentList,
      clearStudents,
      spec,
    };
  },
});
